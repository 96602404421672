import React from 'react';
import { Dialog, Divider, DialogContent } from '@material-ui/core';
import { useEmiConfig } from '@snc/emi-config';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { i18n } from 'i18n';
import NewPasswordForm from './newPassword-form.component';
import ChangePasswordForm from './passwordForm.component';
import CustomDialogTitle from '../modal-title/modal-title.component';

const ChangePasswordDialog = props => {
    const config = useEmiConfig();
    const passwordSecurity = React.useMemo(
        () => ({
            enabled: config.AUTH?.PASSWORD_SECURITY?.ENABLED,
            minLenght: config.AUTH?.PASSWORD_SECURITY?.MIN_LENGTH || 0,
            allowWhiteSpaces: config.AUTH?.PASSWORD_SECURITY?.ALLOW_WHITE_SPACES,
            complex: config.AUTH?.PASSWORD_SECURITY?.COMPLEX,
        }),
        [config.AUTH?.PASSWORD_SECURITY]
    );

    const initialValues = React.useMemo(() => ({ newPassword: '', confirmNewPassword: '' }), []);

    const validationSchema = React.useMemo(() => {
        let newPasswordSchema = Yup.string().required(i18n.newPasswordRequired);
        if (passwordSecurity.minLenght) {
            newPasswordSchema = newPasswordSchema.min(passwordSecurity.minLenght, i18n.passwordStrengthLength);
        }
        if (passwordSecurity.allowWhiteSpaces === false) {
            newPasswordSchema = newPasswordSchema.matches(/^(?!.* ).*$/, {
                message: i18n.passwordStrengthSpaces,
                excludeEmptyString: true,
            });
        }
        if (passwordSecurity.complex) {
            newPasswordSchema = newPasswordSchema.matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$/, {
                message: i18n.passwordStrengthComplex,
                excludeEmptyString: true,
            });
        }

        return Yup.object({
            newPassword: passwordSecurity.enabled ? newPasswordSchema : Yup.string().required(i18n.newPasswordRequired),
            confirmNewPassword: Yup.string()
                .oneOf([Yup.ref('newPassword')], i18n.passwordMatch)
                .required(i18n.confirmPassword),
        });
    }, [passwordSecurity]);

    const { open, onClose, onSubmit, isNew } = props;
    return (
        <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={'sm'} scroll={'paper'} disableBackdropClick>
            <CustomDialogTitle handleCloseDialog={onClose}>{isNew ? i18n.setPassword : i18n.changePassword}</CustomDialogTitle>
            <Divider />
            <DialogContent>
                <div className="p-2">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            setSubmitting(false);
                            onSubmit(values);
                            resetForm();
                        }}
                        component={componentProps =>
                            isNew ? (
                                <NewPasswordForm {...componentProps} {...props} />
                            ) : (
                                <ChangePasswordForm {...componentProps} {...props} />
                            )
                        }
                    />
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default ChangePasswordDialog;
